import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLoginMutation, useSignupMutation } from "../../util/client/TcApiV2";
import { useEffect, useState } from "react";
import { setToken } from "../../util/auth/authSlice";
import { useSearchParams } from "react-router-dom";

export const LOCALSTORAGE_TOKEN_KEY = "tcToken";
const windowAny = window as any;

export const useAuth = () => {
  const isInWebview = windowAny.ReactNativeWebView;
  const [searchParams] = useSearchParams();
  const isHidingToolbar =
    (searchParams.get("toolbar") || "") === "hide" || isInWebview;
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [
    doLogin,
    { data: loginData, isLoading: isLoginLoading, error: loginError },
  ] = useLoginMutation();
  const [
    doSignup,
    { data: signupData, isLoading: isSignupLoading, error: signupError },
  ] = useSignupMutation();
  const [mostRecentAction, setMostRecentAction] = useState<
    undefined | "login" | "signup"
  >();
  const authResponse = loginData || signupData;
  const isLoading = isLoginLoading || isSignupLoading;
  const dispatch = useDispatch();
  const doAuthAction = (
    action: "login" | "signup",
    email: string,
    password: string
  ) => {
    setMostRecentAction(action);
    if (action === "login") {
      doLogin({ payload: { username: email, password } });
    }
    if (action === "signup") {
      doSignup({ payload: { username: email, password } });
    }
  };
  const logout = async () => {
    setMostRecentAction(undefined);
    dispatch(setToken(undefined));
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
  };

  const updateToken = (token: string) => {
    dispatch(setToken(token));
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
  };

  useEffect(() => {
    if (authResponse) {
      console.log(`auth! ${authResponse}`, authResponse.token);
      dispatch(setToken(authResponse.token));
      localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, authResponse.token);
    }
  }, [authResponse]);
  const isAuthenticated = !!token;
  const authError = mostRecentAction === "login" ? loginError : signupError;
  return {
    token,
    setToken,
    doAuthAction,
    isLoading,
    isAuthenticated,
    logout,
    authError,
    updateToken,
    isInWebview,
    isHidingToolbar,
  };
};
