import { ReactNode, useState } from "react";
import { FormSchema } from "./types";
import { Box, Button, Typography } from "@mui/material";
import TcForm from "./TcForm";
import PageTemplate from "../page/PageTemplate";

function validateEmail(email: string): boolean {
  // Define a regular expression for validating email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regular expression
  return emailRegex.test(email);
}

export type FormValidationErrors = { [key: string]: string };
const validateForm = <T extends {}>(
  schema: FormSchema,
  data: T
): FormValidationErrors => {
  const errors: FormValidationErrors = {};
  const flatItems = schema.flat();
  flatItems.forEach((item) => {
    //@ts-ignore
    const value = data[item.propName];
    if (item.required && (value === "" || value === undefined)) {
      errors[item.propName] = "This field is required";
    } else if (item.type === "number") {
      if (item.max !== undefined && value > item.max) {
        errors[item.propName] = `Must be a maximum of ${item.max}`;
      } else if (item.min !== undefined && value < item.min) {
        errors[item.propName] = `Must be a minimum of ${item.min}`;
      }
    } else if (item.type === "email") {
      if (!validateEmail(value)) {
        errors[item.propName] = `Invalid email`;
      }
    }
  });
  return errors;
};

export type FormPageLayoutProps<T extends {}> = {
  title: string;
  schema: FormSchema;
  defaultValue?: Partial<T>;
  submitButtonText?: string;
  onSubmit?: (form: T) => void;
  onValidate?: (formData: T) => FormValidationErrors;
  children?: ReactNode;
  isLoading?: boolean;
};
export const FormPageLayout = <T extends {}>({
  schema,
  defaultValue,
  title,
  submitButtonText,
  onSubmit,
  onValidate,
  children,
  isLoading,
}: FormPageLayoutProps<T>) => {
  const [formData, setFormData] = useState<T>({} as T);
  const [formErrors, setFormErrors] = useState<FormValidationErrors>({});
  const preSubmit = () => {
    const innerErrors = validateForm(schema, formData);
    const outerErrors = onValidate ? onValidate(formData) : {};
    const errors = { ...innerErrors, ...outerErrors };
    setFormErrors(errors);
    const keys = Object.keys(errors);
    if (keys.length === 0) {
      onSubmit?.(formData);
    }
  };
  return (
    <PageTemplate>
      <Typography textAlign="center" variant="h3">
        {title}
      </Typography>
      <TcForm
        schema={schema}
        onFormChange={setFormData}
        defaultValues={defaultValue}
        errors={formErrors}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" onClick={preSubmit} disabled={isLoading}>
          {submitButtonText || "Submit"}
        </Button>
      </Box>
      {children}
    </PageTemplate>
  );
};
