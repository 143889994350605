import { AxiosInstance } from "axios";
import Joi from "joi";

type HttpMethod = "PUT" | "POST" | "GET" | "DELETE";

const NYI = () => {
  throw new Error("Not Yet Implemented");
};

const joiValidationOptions: Joi.ValidationOptions = {
  stripUnknown: true,
  allowUnknown: false,
  convert: true,
  abortEarly: false,
  presence: "required",
};

type CreateParams = {
  endpointName: string;
  method: HttpMethod;
  path: string;
  requestValidator?: Joi.Schema;
  responseValidator?: Joi.Schema;
};

type Path<P> = [P] extends [never]
  ? { pathParams?: undefined }
  : { pathParams: P };
type Payload<Req> = [Req] extends [never]
  ? { payload?: undefined }
  : { payload: Req };
type Query<Q> = [Q] extends [never]
  ? { queryParams?: undefined }
  : { queryParams: Q };

type ApiRequest<
  QueryType = never,
  PathParams = never,
  RequestType = never
> = Path<PathParams> & Payload<RequestType> & Query<QueryType>;

export class TcEndpoint<
  ResponseType extends any,
  QueryType = never,
  PathParams = never,
  RequestType = never
> {
  // static get = <ResponseType = never, QueryType = never, PathParams = never>(
  //   endpointName: string,
  //   path: string
  // ) => {
  //   return new TcEndpoint<ResponseType, QueryType, PathParams, never>({
  //     endpointName,
  //     method: "GET",
  //     path,
  //   });
  // };

  // static delete = <ResponseType = never, QueryType = never, PathParams = never>(
  //   endpointName: string,
  //   path: string
  // ) => {
  //   return new TcEndpoint<ResponseType, QueryType, PathParams, never>({
  //     endpointName,
  //     method: "DELETE",
  //     path,
  //   });
  // };

  // static post = <
  //   ResponseType = never,
  //   QueryType = never,
  //   PathParams = never,
  //   RequestType = never
  // >(
  //   endpointName: string,
  //   path: string
  // ) => {
  //   return new TcEndpoint<ResponseType, QueryType, PathParams, RequestType>({
  //     endpointName,
  //     method: "POST",
  //     path,
  //   });
  // };
  // static put = <
  //   ResponseType = never,
  //   QueryType = never,
  //   PathParams = never,
  //   RequestType = never
  // >(
  //   endpointName: string,
  //   path: string
  // ) => {
  //   return new TcEndpoint<ResponseType, QueryType, PathParams, RequestType>({
  //     endpointName,
  //     method: "PUT",
  //     path,
  //   });
  // };

  public readonly endpointName: string;
  public readonly method: HttpMethod;
  public readonly path: string;
  public readonly requestValidator?: Joi.Schema;
  public readonly responseValidator?: Joi.Schema;
  public requireAuth: boolean;
  private requestHandler: (req: {
    query: QueryType;
    pathParams: PathParams;
    req: RequestType;
    userId?: string;
  }) => Promise<ResponseType> = NYI;

  constructor({
    endpointName,
    method,
    path,
    requestValidator,
    responseValidator,
  }: CreateParams) {
    this.endpointName = endpointName;
    this.method = method;
    this.path = path;
    this.requireAuth = true;
    this.requestValidator = requestValidator;
    this.responseValidator = responseValidator;
  }

  handleRequest = async (req: {
    query: QueryType;
    pathParams: PathParams;
    req: RequestType;
    userId?: string;
  }): Promise<ResponseType> => {
    let validatedPayload = req.req;
    if (this.requestValidator) {
      const requestValidation = this.requestValidator.validate(
        req.req,
        joiValidationOptions
      );
      validatedPayload = requestValidation.value;
    }
    const response = await this.requestHandler({
      ...req,
      req: validatedPayload,
    });
    let validatedResponse = response;
    if (this.responseValidator) {
      console.log("Validating response");
      const responseVaidation = this.responseValidator.validate(
        response,
        joiValidationOptions
      );
      validatedResponse = responseVaidation.value;
      console.log("Response validated.");
    }
    return validatedResponse;
  };

  setRequestHandler = (
    requireAuth: boolean,
    handler: (req: {
      query: QueryType;
      pathParams: PathParams;
      req: RequestType;
      userId?: string;
    }) => Promise<ResponseType>
  ) => {
    this.requireAuth = requireAuth;
    this.requestHandler = handler;
  };

  getUrl = (pathParams?: PathParams) => {
    let url = this.path;
    const params: any = pathParams || {};
    if (params) {
      Object.keys(params).map((pathParamName) => {
        url = url.replaceAll(`:${pathParamName}`, params[pathParamName]);
      });
    }
    return url;
  };

  // this function is to be used to extract the type of the api request and the response from the object
  requestFn = (
    request: ApiRequest<QueryType, PathParams, RequestType>
  ): ResponseType => {
    throw new Error("Do not use");
  };

  submitRequest = (
    axios: AxiosInstance,
    request: ApiRequest<QueryType, PathParams, RequestType> & { auth?: string }
  ) => {
    const { pathParams, queryParams, payload, auth } = request;
    const url = this.getUrl(pathParams);
    console.log(`Making request to ${this.method} ${url}`);
    try {
      return axios.request<ResponseType>({
        url,
        method: this.method,
        params: queryParams,
        data: payload,
        headers: { Authorization: auth },
      });
    } catch (e) {
      console.warn("Error in request: ", e);
      throw e;
    }
  };
}

export type ApiModule = {
  [name: string]: ApiModule | TcEndpoint<any, any, any, any>;
};
export type ApiConfig = { [moduleName: string]: ApiModule };
