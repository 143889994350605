import { LinkProps, Link as MuiLink } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

type Props = RouterLinkProps;
export const TcLink = ({ children, ...rest }: Props) => {
  return (
    <MuiLink component={RouterLink} {...rest}>
      {children}
    </MuiLink>
  );
};
