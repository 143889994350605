import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import { PageFooter } from "../marketing/PageFooter";
import { marketingConstants } from "../marketing/constants";
import { Link } from "react-router-dom";
const HomePage = () => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {/* Hero Section */}
        <Box
          sx={{
            height: "70vh",
            backgroundImage: "url('/img/topography.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            // color: "white",
            padding: 2,
          }}
        >
          <Typography variant="h2" fontWeight="bold" gutterBottom>
            Your Personalized Trail Explorer
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Create and share custom trail collections, discover curated regions,
            and explore offline—all without the hassle of signups.
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ m: 1 }}
              component={Link}
              to="/download"
            >
              Download Now
            </Button>
          </Box>
        </Box>

        {/* About Section */}
        <Container sx={{ py: 8 }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            About Trail Companion
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ maxWidth: 800, mx: "auto", mb: 4 }}
          >
            Trail Companion makes trail exploration simple. Whether you’re an
            offroader, hiker, biker, business, or anyone else, you can create
            custom regions, share them with others, and explore offline maps—all
            for free. Say goodbye to cluttered apps and hello to a streamlined
            experience designed for enthusiasts like you.
          </Typography>
        </Container>

        {/* Key Features Section */}
        <Box sx={{ py: 8, backgroundColor: "#f9f9f9" }}>
          <Container>
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="center"
              gutterBottom
            >
              Key Features
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {[
                {
                  title: "Custom Trail Collections",
                  description:
                    "Create your own trail groupings (regions) and share them with others, whether you’re planning a trip with friends or showcasing trails for your business.",
                },
                {
                  title: "Curated Regions",
                  description:
                    "Discover regions created by businesses, clubs, and local trail enthusiasts—explore their recommendations with ease.",
                },
                {
                  title: "Offline Maps",
                  description:
                    "No service? No problem. Download maps for offline use and access them anywhere, anytime.",
                },
                {
                  title: "No Account Required",
                  description:
                    "Enjoy the app without the hassle of creating an account—perfect for quick and easy access to trails.",
                },
                {
                  title: "Business-Friendly Tools",
                  description:
                    "Embed trail maps on your website, share curated trail collections, and provide customers with an effortless way to explore trails.",
                },
              ].map((feature, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* How It Works Section */}
        <Container sx={{ py: 8 }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            How It Works
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                step: "1",
                title: "Download the App",
                description:
                  "Available on the App Store and Google Play, Trail Companion is ready for your next adventure.",
              },
              {
                step: "2",
                title: "Download Regions",
                description:
                  "Browse curated regions tailored by businesses, local clubs, and outdoor enthusiasts.",
              },
              {
                step: "3",
                title: "Go!",
                description:
                  "Once downloaded, you get full access to offline maps for that area so even if you have no service you'll still be able to find your way back",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color="primary"
                  gutterBottom
                >
                  {item.step}
                </Typography>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body2">{item.description}</Typography>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Call-to-Action Section */}
        <Box
          sx={{
            py: 6,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Ready to hit the trails?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Download Trail Companion today and start exploring your next
            adventure.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{ mx: 2 }}
            component={Link}
            to={marketingConstants.iosStoreLink}
            target="_blank"
          >
            Download on the App Store
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{ mx: 2 }}
            component={Link}
            to={marketingConstants.androidStoreLink}
            target="_blank"
          >
            Get it on Google Play
          </Button>
        </Box>
      </Box>
      <PageFooter />
    </>
  );
};

export default HomePage;
