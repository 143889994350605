import React from "react";
import L, { DivIcon, DragEndEvent } from "leaflet";
import { Marker } from "react-leaflet";
import { lngLatToLatLng } from "../../util/regionUtils";
import { Drt } from "../../lib";
import * as Icons from "@mdi/js";

import _ from "lodash";

export const getIconText = (iconName: string, markerSize: number) => {
  const mdiIconName = `mdi${_.upperFirst(_.camelCase(iconName))}`;
  console.log("mdiIconName", mdiIconName);
  // @ts-ignore-next-line
  if (Icons[mdiIconName]) {
    const fontSize = Math.floor(markerSize * 0.65);
    const maybeTopMargin = Math.floor(markerSize * 0.08);
    const topMargin =
      maybeTopMargin % 2 === 1 ? maybeTopMargin : maybeTopMargin + 1;
    return `<span style="font-size:${fontSize}px; margin-top: ${topMargin}px; line-height:${fontSize}px ;" class="mdi mdi-${_.kebabCase(
      iconName
    )}"></span>`;
  }
  return `<i style="font-size:${
    markerSize * 0.5
  }px" class="fa fa-${iconName}"></i>`;
};

export const getMarkerIcon = (
  props: Drt.PointOfInterestProperties,
  isSelected: boolean,
  markerSize: number = 32
): DivIcon => {
  const icon = props.icon || "location-arrow";
  const iconColor = props.iconColor || "#FFF";
  const pinColor = props.pinColor || "#0088FF";
  const borderColor = isSelected
    ? "#8800FF"
    : props.pinBorderColor || "#000000";
  const borderSize = (markerSize / 16) * (isSelected ? 2 : 1);
  const html = `
    <div class="drt-marker" style="background-color:${pinColor};border-radius: ${markerSize}px ${markerSize}px 0px ${markerSize}px;transform:rotate(45deg);width:${markerSize}px;height:${markerSize}px;display:flex;justify-content:center;align-items:center;pointer-events:none;border-color:${borderColor};border-width:${borderSize}px;border-style:solid;">
      <div class="drt-marker-icon" style="transform:rotate(-45deg);color:${iconColor};pointer-events:none;">${getIconText(
    icon,
    markerSize
  )}</div>
    </div>
  `;
  const width = markerSize;
  const height =
    markerSize + (Math.sqrt(2 * markerSize * markerSize) - markerSize) / 2;
  const divIcon = new DivIcon({
    html: html,
    iconAnchor: [width / 2, height],
    iconSize: [width, height],
  });
  return divIcon;
};

type IconMarkerProps = {
  marker: Drt.PointOfInterest;
  isSelected: boolean;
  onGeometryClick?: (geometry: Drt.TrailGeometry) => void;
  onUpdateCoordinates?: <T extends Drt.TrailGeometry>(
    geometry: T,
    newCoords: T["coordinates"]
  ) => void;
};
const IconMarker = ({
  marker,
  isSelected,
  onGeometryClick,
  onUpdateCoordinates,
}: IconMarkerProps) => {
  return (
    <Marker
      position={lngLatToLatLng(marker.coordinates)}
      icon={getMarkerIcon(marker.properties, isSelected)}
      draggable={isSelected}
      eventHandlers={{
        ...(onGeometryClick
          ? {
              click: () => {
                onGeometryClick(marker);
              },
            }
          : {}),
        ...(onUpdateCoordinates
          ? {
              dragend: (e: DragEndEvent) => {
                L.DomEvent.stop(e);
                const { lat, lng } = e.target.getLatLng();
                onUpdateCoordinates(marker, [lng, lat]);
              },
            }
          : {}),
      }}
    />
  );
};

export default IconMarker;
