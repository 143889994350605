import { MenuItem, TextField, Link, Box, debounce } from "@mui/material";
import React, { ChangeEvent, useCallback } from "react";
import { Drt } from "../../lib/Drt";
import TcForm from "../../components/tc-form/TcForm";
import { markerIconForm } from "../../forms/partnerConfigForm";
import { getIconText } from "../../components/leaflet/IconMarker";

const schema = markerIconForm(false);

type PoiEditorProps = {
  poi: Drt.PointOfInterest;
  defaultPois: Drt.PointOfInterestProperties[];
  onEditGeometryProperties: (
    geometryId: string,
    newProps: Partial<Drt.PointOfInterestProperties>
  ) => void;
};

const findPreset = (
  pois: Drt.PointOfInterestProperties[],
  currentProps: Drt.PointOfInterestProperties
): string =>
  pois.find(
    ({ icon, iconColor, pinColor, pinBorderColor, renderLocationQr }) =>
      currentProps.icon === icon &&
      currentProps.iconColor === iconColor &&
      currentProps.pinBorderColor === pinBorderColor &&
      currentProps.pinColor === pinColor &&
      currentProps.renderLocationQr === renderLocationQr
  )?.id || "Custom";

const PoiEditor = ({
  poi,
  defaultPois,
  onEditGeometryProperties,
}: PoiEditorProps) => {
  const presetOptions = ["Custom", ...defaultPois.map((poi) => poi.id)];
  const currentPreset = findPreset(defaultPois, poi.properties);
  const selectPreset = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const selectedPoi = defaultPois.find((prop) => prop.id === newValue);
      if (selectedPoi) {
        const { icon, iconColor, pinColor, pinBorderColor, renderLocationQr } =
          selectedPoi;
        const newProps = {
          ...poi.properties,
          icon,
          iconColor,
          pinColor,
          pinBorderColor,
          renderLocationQr,
        };
        onEditGeometryProperties(poi.properties.id, newProps);
      }
    },
    [defaultPois, onEditGeometryProperties, poi.properties]
  );

  const onPoiChange = useCallback(
    debounce((data: Drt.PointOfInterestProperties) => {
      onEditGeometryProperties(poi.properties.id, data);
    }, 500),
    [onEditGeometryProperties, poi.properties.id]
  );
  console.log(poi.properties);
  return (
    <>
      {/* <Link
        href={`/partner/${partnerId}/partner-config/${partnerConfigId}`}
      >
        Edit Presets
      </Link> */}
      <TextField
        label="Preset"
        select
        fullWidth
        value={currentPreset}
        onChange={selectPreset}
        size="small"
        sx={{ mb: 1 }}
      >
        {presetOptions.map((option) => (
          <MenuItem key={option} value={option}>
            <span
              dangerouslySetInnerHTML={{
                __html: getIconText(
                  defaultPois.find((prop) => prop.id === option)?.icon ||
                    poi.properties.icon,
                  24
                ),
              }}
            ></span>
            &nbsp;&nbsp;
            {option}
          </MenuItem>
        ))}
      </TextField>
      <span>
        {" "}
        <Link href="https://fontawesome.com/v4/icons/" target="_blank">
          FontAwesome
        </Link>{" "}
        or{" "}
        <Link href="https://pictogrammers.com/library/mdi/" target="_blank">
          Material UI
        </Link>{" "}
        Icons available
      </span>
      {/* Find a better way of showing this */}
      {/* <Box sx={{ p: 0.5, display: "flex", alignItems: "center" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: getIconText(poi.properties.icon, 32),
          }}
        ></div>
      </Box> */}
      <TcForm
        schema={schema}
        onFormChange={onPoiChange}
        defaultValues={poi.properties}
      />
    </>
  );
};

export default PoiEditor;
