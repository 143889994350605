import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageFooter } from "./PageFooter";

const faqs = [
  {
    question: "What is Trail Companion?",
    answer:
      "Trail Companion is an app that lets you create and share personalized groupings of trails, making it perfect for individuals, clubs, and businesses.",
  },
  {
    question: "Why would I use Trail Companion over other trail apps?",
    answer:
      "Trail Companion complements other trail apps by simplifying trail exploration. It helps users avoid information overload by allowing businesses, clubs, and enthusiasts to create and share curated trail collections, called regions.",
  },
  {
    question: "What is a region and what do I need to create one?",
    answer:
      "A region is a custom collection of trails. To create one, all you need are mapping files (GPX, KML, GeoJSON) to start building your own trail groupings.",
  },
  {
    question: "Can I have offline maps?",
    answer:
      "Yes! You can download maps for offline use, giving you access to trails anywhere. The only limit is your phone's storage.",
  },
  {
    question: "Do I need to create an account?",
    answer:
      "You only need an account if you'd like to create regions. Otherwise, the app is completely free to use and doesn’t require logging in, even to download offline maps.",
  },
  {
    question: "How do I download the app?",
    answer:
      "You can download the app from the Apple App Store or Google Play Store. Visit the 'Download' section on our homepage to get started.",
  },
  {
    question: "Is the app free to use?",
    answer:
      "Yes, Trail Companion is completely free to use, with no ads and no hidden fees.",
  },
  {
    question: "What kind of businesses can benefit from Trail Companion",
    answer:
      "Trail Companion is ideal for businesses that benefit from showing maps to customers, such as offroad parks, vehicle rental companies, national parks, and welcome centers. You can even embed maps on your website, providing customers with an easy, free way to access trails on their phones without needing to sign up or provide personal information.",
  },
  {
    question: "What devices does the app support?",
    answer:
      "Trail Companion is available for iOS and Android devices. We recommend using the latest version of the app for the best experience.",
  },
  {
    question:
      "It's free, you don't show ads, you don't sell our data, what's in it for you?",
    answer:
      "This started out as a passion project for me back in 2017 when I was gearing up to do the trans-american trail. I knew I would frequently be in areas without service and didn't want to rely on paper maps alone to get me through my trip. So I started building this app and have been using it for personal use ever since. I can't say that there will never be a paid feature or that I'll never show an ad, but I can say that everything that is currently free will remain free, there may be an unintrusive ad that gets shown in the future but as long as the server costs remain relatively low, I am 100% happy with providing this for people like me who dislike paying for apps.",
  },
];

export const FaqPage = () => {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
        {/* Page Header */}
        <Typography variant="h3" align="center" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Have questions? We've got answers! If you don't find what you're
          looking for, feel free to reach out to us.
        </Typography>

        {/* FAQ Section */}
        <Box sx={{ mt: 4 }}>
          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="textSecondary">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
      <PageFooter />
    </>
  );
};
