import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./authSlice";
import { LOCALSTORAGE_TOKEN_KEY, useAuth } from "../../auth/hooks/useAuth";
import { useLazyVerifyTokenQuery } from "../client/TcApiV2";
import { RootState } from "../../store/store";

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  return <AuthProviderInternal>{children}</AuthProviderInternal>;
};

const AuthProviderInternal = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [isLoaded, setLoaded] = useState(false);
  const [triggerVerify, { isSuccess, error }] = useLazyVerifyTokenQuery();
  useEffect(() => {
    const lsToken = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
    if (lsToken) {
      dispatch(setToken(lsToken));
    } else {
      dispatch(setToken(undefined));
      setLoaded(true);
    }
  }, []);

  // when token gets set, verify it.
  useEffect(() => {
    if (token) {
      triggerVerify({});
    }
  }, [token]);

  //if we get error verifying token, log the user out and set loaded to true
  useEffect(() => {
    const e = error as any;
    if (e && e.status === 401 && e.data?.message === "Invalid Token") {
      dispatch(setToken(undefined));
      localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
      setLoaded(true);
    }
  }, [error]);

  //if token verify is success, set loaded to true
  useEffect(() => {
    if (isSuccess) {
      setLoaded(true);
    }
  }, [isSuccess]);

  // Don't show anything until we know whether or not a token exists in localStorage and is verified
  if (!isLoaded) {
    return <></>;
  }
  return children;
};

export default AuthProvider;
