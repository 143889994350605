import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PageFooter } from "./PageFooter";
import { TcLink } from "../../components/basic/TcLink";

export const BusinessOwnersPage = () => {
  return (
    <>
      {" "}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* Header Section */}
        <Typography variant="h3" align="center" gutterBottom>
          Why Partner with Trail Companion?
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Enhance your business by connecting with outdoor enthusiasts through
          our innovative trail navigation platform.
        </Typography>

        {/* Benefits Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {[
            {
              title: "Completely Free",
              description:
                "Trail Companion is free for you and your customers, with no hidden costs or fees.",
            },
            {
              title: "Full Map Control",
              description:
                "Show your customers only the trails and routes you want them to see. Instead of letting them search through endless options, provide a curated list that aligns with your business goals.",
            },
            {
              title: "Dedicated Support",
              description:
                "As a small, agile team, we’re here to help your business succeed. We quickly adapt to your needs, develop features based on your feedback, and ensure you get the personalized support you deserve.",
            },
          ].map((benefit, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ backgroundColor: "#fff" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Feature Highlights Section */}
        <Typography variant="h4" align="center" sx={{ mt: 6, mb: 3 }}>
          Key Features for Businesses
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: "Embed Maps",
              description:
                "Easily embed interactive trail maps on your website, allowing customers to explore suggested trails directly from your site.",
            },
            {
              title: "No account required",
              description:
                "Your customers can start exploring trails instantly, without the hassle of creating an account.",
            },
            {
              title: "Effortless Region Creation",
              description:
                "Quickly create and share custom trail regions using our intuitive, user-friendly tools.",
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ backgroundColor: "#fff" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Call to Action */}
        <Stack alignItems="center" spacing={2} sx={{ mt: 6 }}>
          <Typography variant="h5" align="center">
            Ready to Boost Your Business?
          </Typography>
          <Link to="/login">Get Started Today</Link>
          <Typography variant="body2" color="textSecondary">
            Have questions? <TcLink to="/contact">Contact us</TcLink>.
          </Typography>
        </Stack>
      </Container>
      <PageFooter />
    </>
  );
};
