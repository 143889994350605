import { Link, Typography } from "@mui/material";
import { FormPageLayout } from "../../components/tc-form/FormPageLayout";
import { FormSchema } from "../../components/tc-form/types";
import { useChangePasswordMutation } from "../../util/client/TcApiV2";
import { Link as RouterLink } from "react-router-dom";
import { TcLink } from "../../components/basic/TcLink";
import { useEffect } from "react";
import { useAuth } from "../../auth/hooks/useAuth";

const windowAny = window as any;

const schema: FormSchema = [
  [
    {
      type: "password",
      propName: "currentPassword",
      label: "Current Password",
      required: true,
    },
  ],
  [
    {
      type: "password",
      propName: "newPassword",
      label: "New Password",
      required: true,
    },
  ],
  [
    {
      type: "password",
      propName: "newPassword2",
      label: "Repeat Password",
      required: true,
    },
  ],
];

type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  newPassword2: string;
};

export const ChangePasswordPage = () => {
  const { updateToken } = useAuth();
  const [triggerChangePassword, { data, isLoading, isError }] =
    useChangePasswordMutation();

  useEffect(() => {
    if (data) {
      windowAny.ReactNativeWebView?.postMessage(
        JSON.stringify({ newToken: data.token })
      );
      updateToken(data.token);
    }
  }, [data]);
  return (
    <FormPageLayout<ChangePasswordForm>
      schema={schema}
      title={"Change Password"}
      isLoading={isLoading || !!data}
      onValidate={(data) => {
        const errors: any = {};
        if (data.currentPassword === data.newPassword) {
          errors.newPassword = "New password cannot be same as old one";
        }
        if (data.newPassword !== data.newPassword2) {
          errors.newPassword2 = "Both password fields must match!";
        }
        return errors;
      }}
      onSubmit={(data) => {
        triggerChangePassword({
          payload: {
            oldPassword: data.currentPassword,
            newPassword: data.newPassword,
          },
        });
      }}
    >
      {isError ? (
        <>
          <Typography>
            Error changing password. Forgot your password? Try to{" "}
            <TcLink to="/account/forgot-password">reset password</TcLink>{" "}
            instead!
          </Typography>
        </>
      ) : undefined}
      {data ? (
        <>
          <Typography>
            Password changed successfully!{" "}
            <TcLink to="/dashboard">Continue to dashboard</TcLink>
          </Typography>
        </>
      ) : undefined}
    </FormPageLayout>
  );
};
