import { Container, Typography, Grid, Stack, Button, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DiscordIcon from "@mui/icons-material/SportsEsports";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { PageFooter } from "./PageFooter";

const socials = [
  {
    name: "Email",
    link: "mailto:support@trailcompanion.app",
    icon: <EmailIcon />,
  },
  {
    name: "Discord",
    link: "https://discord.gg/Fk3UykWkx7",
    icon: <DiscordIcon />,
  },
  {
    name: "Instagram",
    link: "https://instagram.com/trailcompanion",
    icon: <InstagramIcon />,
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/@TrailCompanion",
    icon: <InstagramIcon />,
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/yourapp",
  //   icon: <TwitterIcon />,
  // },
  // {
  //   name: "Facebook",
  //   link: "https://facebook.com/yourapp",
  //   icon: <FacebookIcon />,
  // },
];

export const ContactUsPage = () => {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
        {/* Header */}
        <Typography variant="h3" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Have questions or need support? Get in touch with us through any of
          the options below!
        </Typography>

        {/* Social Media and Contact Options */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {socials.map((social, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                onClick={() => window.open(social.link, "_blank")}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                  transition: "background-color 0.3s",
                  "&:hover": { backgroundColor: "action.hover" },
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1.5,
                    backgroundColor: "primary.main",
                    color: "white",
                    borderRadius: "50%",
                  }}
                >
                  {social.icon}
                </Box>
                <Box>
                  <Typography variant="h6">{social.name}</Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
      <PageFooter />
    </>
  );
};
