import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { TermsContent } from "./content";
import { PageFooter } from "../marketing/PageFooter";
import { useLocation } from "react-router-dom";
import React from "react";

export const TermsPage = () => {
  const scrolledRef = React.useRef(false);
  const { hash } = useLocation();
  React.useEffect(() => {
    console.log("");
    if (hash && !scrolledRef.current) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        scrolledRef.current = true;
      }
    }
  });
  return (
    <>
      <Box sx={{ p: 2, maxWidth: 800, mx: "auto" }}>
        <Link href="#terms">Terms Of Use</Link> |{" "}
        <Link href="#privacypolicy">Privacy Policy</Link>
        {/* @ts-ignore-next-line */}
        <a name="terms" id="terms"></a>
        <Typography variant="h3">Terms Of Use</Typography>
        <ReactMarkdown>{TermsContent.terms}</ReactMarkdown>
        <hr />
        {/* @ts-ignore-next-line */}
        <a name="privacypolicy" id="privacypolicy"></a>
        <Typography variant="h3">Privacy Policy</Typography>
        <ReactMarkdown>{TermsContent.privacyPolicy}</ReactMarkdown>
      </Box>
      <PageFooter />
    </>
  );
};
