import { TcApi } from "@trailcompanion/tc-common";
import React from "react";
import RegionCard from "./RegionCard";
import CreateRegion from "./CreateRegion";
import { Box, Typography } from "@mui/material";

type UserDashboardProps = {
  regions: TcApi.RegionListItem[];
  onRefresh: () => void;
};

export const UserDashboard = ({ regions, onRefresh }: UserDashboardProps) => {
  return (
    <Box>
      <Typography variant="h3">My Regions</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {regions.map((region) => (
          <RegionCard
            region={region}
            key={region.regionId}
            onRefresh={onRefresh}
          />
        ))}
        <CreateRegion triggerRefresh={onRefresh} />
      </Box>
    </Box>
  );
};
