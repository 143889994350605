import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "./DashboardCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApiCommon } from "../../lib";
import {
  useCopyRegionMutation,
  useDeleteRegionMutation,
} from "../../util/client/TcApiV2";
import { TcApi } from "@trailcompanion/tc-common";

type RegionCardProps = {
  region: TcApi.RegionListItem;
  onRefresh: () => void;
};
const RegionCard = ({ region, onRefresh }: RegionCardProps) => {
  const [triggerCopyRegion] = useCopyRegionMutation();
  const [triggerDeleteRegion] = useDeleteRegionMutation();
  const onClickClone = useCallback(() => {
    triggerCopyRegion({ pathParams: { regionId: region.regionId } }).then(
      onRefresh
    );
  }, []);
  const onClickDelete = useCallback(() => {
    triggerDeleteRegion({ pathParams: { regionId: region.regionId } }).then(
      onRefresh
    );
  }, []);
  return (
    <DashboardCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h6">{region.regionName}</Typography>
        <Typography variant="caption">
          {region.numTrails} trails | {region.visibility}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Link to={`/map/${region.regionId}`}>
            <IconButton aria-label="view" color="primary">
              <VisibilityIcon />
            </IconButton>
          </Link>
          <Link to={`/map/${region.regionId}/edit`}>
            <IconButton aria-label="edit" color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton onClick={onClickClone} aria-label="copy" color="primary">
            <ContentCopyIcon />
          </IconButton>
          <IconButton
            onClick={onClickDelete}
            aria-label="delete"
            color="primary"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default RegionCard;
