import {
  Box,
  Button,
  Card,
  Link,
  Paper,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/hooks/useAuth";
import { PageFooter } from "../marketing/PageFooter";

const actionMap = {
  signup: "Sign up",
  login: "Log in",
} as const;

const LoginPage = () => {
  const [action, setAction] = useState<"login" | "signup">("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { doAuthAction, isAuthenticated, isLoading, authError } = useAuth();
  const onActionClick = () => {
    doAuthAction(action, email, password);
  };
  useEffect(() => {
    console.log("Token change", isAuthenticated);
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (authError) {
      if (action === "login") {
        setErrorMessage(
          "Login error! Don't have an account yet? Try signing up instead"
        );
      } else if (action === "signup") {
        setErrorMessage(
          "Signup error! Already have an account? Try logging in instead"
        );
      }
    }
  }, [authError]);

  const onActionChange = (
    event: React.MouseEvent<HTMLElement>,
    newAction: string | null
  ) => {
    if (newAction) {
      setAction(newAction as "login" | "signup");
      setErrorMessage("");
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Card sx={{ p: 3, backgroundColor: "#ffffff", minWidth: 500 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ToggleButtonGroup
              value={action}
              exclusive
              onChange={onActionChange}
              aria-label="text alignment"
            >
              <ToggleButton
                value="login"
                aria-label="log in"
                sx={{ flexGrow: 1 }}
              >
                Login
              </ToggleButton>
              <ToggleButton
                value="signup"
                aria-label="sign up"
                sx={{ flexGrow: 1 }}
              >
                Sign Up
              </ToggleButton>
            </ToggleButtonGroup>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: 4,
              }}
            >
              <Button
                variant="text"
                onClick={() => navigate("/account/forgot-password")}
                disabled={isLoading}
              >
                Forgot Password
              </Button>
              <Button
                variant="contained"
                onClick={onActionClick}
                disabled={isLoading}
              >
                {actionMap[action]}
              </Button>
            </Box>
            <Typography variant="caption">
              By {action === "login" ? "logging in" : "signing up"} you agree to
              the{" "}
              <Link target="_blank" href="/terms">
                terms of use and privacy policy
              </Link>
            </Typography>
          </Box>
        </Card>
        <Snackbar
          open={!!errorMessage}
          message={errorMessage}
          autoHideDuration={5000}
          onClose={() => setErrorMessage("")}
        />
      </Box>
      <PageFooter />
    </>
  );
};

export default LoginPage;
