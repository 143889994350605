import { Typography } from "@mui/material";
import { useAuth } from "../../auth/hooks/useAuth";
import PageTemplate from "../../components/page/PageTemplate";
import { FormPageLayout } from "../../components/tc-form/FormPageLayout";
import { FormSchema } from "../../components/tc-form/types";
import { useResetPasswordMutation } from "../../util/client/TcApiV2";
import { TcLink } from "../../components/basic/TcLink";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const windowAny = window as any;
const schema: FormSchema = [
  [
    {
      type: "email",
      propName: "email",
      label: "Email",
      required: true,
    },
  ],
  [
    {
      type: "text",
      propName: "resetCode",
      label: "Reset Code",
      required: true,
    },
  ],
  [
    {
      type: "password",
      propName: "newPassword",
      label: "New Password",
      required: true,
    },
  ],
  [
    {
      type: "password",
      propName: "newPassword2",
      label: "Repeat Password",
      required: true,
    },
  ],
];

type ChangePasswordForm = {
  resetCode: string;
  email: string;
  newPassword: string;
  newPassword2: string;
};

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const codeFromSearch = searchParams.get("code") || "";
  const defaultValues = useMemo(
    () => ({ resetCode: codeFromSearch }),
    [codeFromSearch]
  );
  const { isAuthenticated, updateToken } = useAuth();
  const [trigger, { data, isError, isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (data) {
      windowAny.ReactNativeWebView?.postMessage(
        JSON.stringify({ newToken: data.token })
      );
      updateToken(data.token);
    }
  }, [data]);

  if (isAuthenticated && !data) {
    return (
      <PageTemplate>
        <Typography>
          Unable to reset password while logged in.{" "}
          <TcLink to="/dashboard">Back to dashboard</TcLink>
        </Typography>
      </PageTemplate>
    );
  }
  if (isAuthenticated && data) {
    <PageTemplate>
      Password Reset Successfully.{" "}
      <TcLink to="/dashboard">Continue to dashboard</TcLink>
    </PageTemplate>;
  }
  return (
    <FormPageLayout<ChangePasswordForm>
      schema={schema}
      title={"Reset Password"}
      defaultValue={defaultValues}
      isLoading={isLoading || !!data}
      onValidate={(data) => {
        const errors: any = {};
        if (data.newPassword !== data.newPassword2) {
          errors.newPassword2 = "Both password fields must match!";
        }
        return errors;
      }}
      onSubmit={(data) => {
        trigger({
          payload: {
            email: data.email,
            resetCode: data.resetCode,
            newPassword: data.newPassword,
          },
        });
      }}
    >
      <>
        <Typography>
          Don't have a code yet?{" "}
          <TcLink to="/account/forgot-password">
            Go to forgot password page
          </TcLink>
        </Typography>
      </>
      {isError ? (
        <>
          <Typography>
            Error resetting password. Did the code expire?{" "}
            <TcLink to="/account/forgot-password">Try again</TcLink>
          </Typography>
        </>
      ) : undefined}
      {data ? (
        <>
          <Typography>
            Password changed successfully!{" "}
            <TcLink to="/dashboard">Continue to dashboard</TcLink>
          </Typography>
        </>
      ) : undefined}
    </FormPageLayout>
  );
};
