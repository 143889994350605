import joi from "joi";
import { Drt } from "../types/region";
import { TcApi } from "../types/TcApi";
import { Validators } from "../types/validators";
import { TcEndpoint } from "./types";

const getRegionById = new TcEndpoint<
  TcApi.RegionListItem,
  never,
  { regionId: string }
>({
  endpointName: "getRegionById",
  path: "/api/region/:regionId",
  method: "GET",
});

const listRegions = new TcEndpoint<
  TcApi.RegionListItem[],
  TcApi.ListRegionQueryParams,
  never,
  never
>({ endpointName: "listRegions", path: "/api/region", method: "GET" });

const createRegion = new TcEndpoint<
  TcApi.RegionListItem,
  never,
  never,
  TcApi.CreateRegionProps
>({ endpointName: "createRegion", path: "/api/region", method: "POST" });

const getRegionMap = new TcEndpoint<Drt.Region, never, { regionId: string }>({
  endpointName: "getRegionMap",
  path: "/api/region/:regionId/map",
  method: "GET",
  responseValidator: Validators.regionSchema,
});

const updateRegionMap = new TcEndpoint<
  Drt.Region,
  never,
  { regionId: string },
  Drt.Region
>({
  endpointName: "putRegionMap",
  path: "/api/region/:regionId/map",
  method: "PUT",
  responseValidator: Validators.regionSchema,
  requestValidator: Validators.regionSchema,
});

const copyRegion = new TcEndpoint<
  TcApi.RegionListItem,
  never,
  { regionId: string },
  never
>({
  endpointName: "copyRegion",
  path: "/api/region/:regionId/copy",
  method: "POST",
});

const updateRegionVisibility = new TcEndpoint<
  TcApi.RegionListItem,
  never,
  { regionId: string },
  TcApi.UpdateRegionVisibilityRequest
>({
  endpointName: "updateRegionVisibility",
  path: "/api/region/:regionId/visibility",
  method: "PUT",
  requestValidator: joi.object({
    visibility: joi.string().required().valid("public", "private"),
  }),
});

const deleteRegion = new TcEndpoint<{}, never, { regionId: string }, never>({
  endpointName: "deleteRegion",
  path: "/api/region/:regionId",
  method: "DELETE",
});

export const regionModule = {
  getRegionById,
  listRegions,
  createRegion,
  getRegionMap,
  updateRegionMap,
  copyRegion,
  updateRegionVisibility,
  deleteRegion,
};
