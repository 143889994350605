import { FormSchema } from "../components/tc-form/types";
import {
  FormPageLayout,
  FormValidationErrors,
} from "../components/tc-form/FormPageLayout";
import { Box, Typography } from "@mui/material";

const demoForm: FormSchema = [
  [
    {
      type: "text",
      propName: "textFormItem",
      label: "Text Form Item",
      required: true,
      rowWeight: 1,
    },
  ],
  [
    {
      type: "multiline",
      propName: "multilineFormItem",
      label: "Multiline Form Item",
      required: true,
      rowWeight: 1,
      rows: 4,
    },
  ],
  [
    {
      type: "select",
      propName: "selectFormItem",
      label: "Select Form Item",
      required: true,
      rowWeight: 1,
      options: [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
      ],
    },
  ],
  [
    {
      type: "number",
      propName: "numberFormItem",
      label: "Number Form Item",
      min: 5,
      max: 20,
      required: true,
      rowWeight: 1,
    },
  ],
  [
    {
      type: "switch",
      propName: "switchFormItem",
      label: "Switch Form Item",
    },
  ],
  [
    {
      type: "text",
      propName: "textFormItemLong",
      label: "Same Row Longer Form Item",
      required: true,
      rowWeight: 3,
    },
    {
      type: "text",
      propName: "textFormItemShort",
      label: "Same Row Shorter Form Item",
      required: true,
      rowWeight: 2,
    },
  ],
  [
    {
      type: "email",
      propName: "emailFormItem",
      label: "Email Form Item",
      required: true,
      rowWeight: 2,
    },
    {
      type: "password",
      propName: "passwordFormItem",
      label: "Password Form Item",
      required: true,
      rowWeight: 3,
    },
  ],
  [
    {
      type: "color",
      propName: "colorFormItem",
      label: "Color Form Item",
      required: true,
      rowWeight: 2,
    },
  ],
];

export const FormSandboxPage = () => {
  const onValidate = (data: any) => {
    const errors: FormValidationErrors = {};
    if (data.textFormItem !== "Trail Companion") {
      errors["textFormItem"] = "Must equal 'Trail Companion'";
    }
    return errors;
  };
  const onSubmitData = console.log;
  return (
    <FormPageLayout
      schema={demoForm}
      title="TcForm Example"
      onSubmit={onSubmitData}
      onValidate={onValidate}
    >
      <Box>
        <Typography>Example Optional Footer</Typography>
      </Box>
    </FormPageLayout>
  );
};
