import { Box } from "@mui/material";
import { parse } from "path";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import TcFormRow from "./TcFormRow";
import { FormItem, FormSchema } from "./types";

const getDefaultValues = (schema: FormSchema) => {
  const values: any = {};
  const flatSchema = schema.flat();
  flatSchema.forEach((item) => {
    if (
      item.type === "text" ||
      item.type === "multiline" ||
      item.type === "password" ||
      item.type === "email"
    ) {
      values[item.propName] = "";
    } else if (item.type === "color") {
      values[item.propName] = "#000000";
    } else if (item.type === "number") {
      if (item.min !== undefined) {
        values[item.propName] = item.min;
      } else {
        values[item.propName] = 0;
      }
    } else if (item.type === "switch") {
      values[item.propName] = false;
    } else {
      values[item.propName] = "";
    }
  });
  return values;
};

type TcFormProps = {
  schema: FormSchema;
  defaultValues?: { [key: string]: any };
  value?: { [key: string]: any };
  onFormChange: (data: any) => void;
  errors?: any;
};

const TcForm = ({
  schema,
  defaultValues,
  onFormChange,
  value,
  errors,
}: TcFormProps) => {
  const generatedInitialValues = getDefaultValues(schema);
  const [internalValues, setInternalValues] = useState(
    defaultValues
      ? { ...generatedInitialValues, ...defaultValues }
      : generatedInitialValues
  );

  // if defaultValues changes for some reason, let's update the internal values
  useEffect(() => {
    if (defaultValues) {
      console.log("defaultValues changed");
      setInternalValues(defaultValues);
    }
  }, [defaultValues]);

  const onChange = useCallback(
    (item: FormItem, propName: string, rawValue: string, checked?: boolean) => {
      let newValue: string | number | boolean = rawValue;
      if (item.type === "number") {
        newValue = parseFloat(rawValue);
        if (Number.isNaN(newValue)) {
          newValue = 0;
        }
      } else if (item.type === "switch") {
        newValue = !!checked;
      }
      const newValues = {
        ...(value ? value : internalValues),
        [propName]: newValue,
      };
      setInternalValues(newValues);
      onFormChange(newValues);
    },
    [internalValues, value]
  );
  return (
    <Box component="form" autoComplete="off">
      <Box sx={{ dislpay: "flex", flexDirection: "column" }}>
        {schema.map((row, index) => {
          return (
            <TcFormRow
              key={index.toString()}
              row={row}
              values={value || internalValues}
              onChange={onChange}
              errors={errors}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TcForm;
