import React, { useCallback } from "react";
import { Box, Paper } from "@mui/material";
import { useListRegionsQuery } from "../../util/client/TcApiV2";
import _ from "lodash";
import { UserDashboard } from "./UserDashboard";
import { PageFooter } from "../marketing/PageFooter";
import { FullPageLoader } from "../../components/FullPageLoader";

const DashboardPage = () => {
  const {
    data: regions,
    refetch,
    isFetching,
  } = useListRegionsQuery({
    queryParams: { type: "own" },
  });

  const onRefresh = useCallback(() => setTimeout(refetch, 500), [refetch]);

  return (
    <>
      <FullPageLoader isActive={isFetching} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
        }}
      >
        {regions && <UserDashboard regions={regions} onRefresh={onRefresh} />}
      </Box>
      <PageFooter />
    </>
  );
};

export default DashboardPage;
