import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { TcLink } from "../../components/basic/TcLink";
import { useAuth } from "../../auth/hooks/useAuth";

const links = [
  { text: "Contact Us", to: "/contact" },
  { to: "/login", text: "Login" },
  { to: "/login", text: "Signup" },
  { to: "/terms#privacypolicy", text: "Privacy Policy" },
  { to: "/terms#terms", text: "Terms of Use" },
  { to: "/faqs", text: "FAQs" },
  { to: "/business-owners", text: "Business Owners" },
];

export const PageFooter = () => {
  const { isHidingToolbar } = useAuth();
  if (isHidingToolbar) {
    return <></>;
  }
  return (
    <Box
      mt={4}
      mb={2}
      sx={{
        textAlign: "center",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body2">
        {links.map(({ text, to }, i) => (
          <React.Fragment key={i}>
            {i !== 0 ? <> | </> : undefined}
            <TcLink to={to}>{text}</TcLink>
          </React.Fragment>
        ))}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} Trail Companion. All rights reserved.
      </Typography>
    </Box>
  );
};
