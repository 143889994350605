import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Container, Typography, Button, Stack } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PageFooter } from "./PageFooter";
import { useAuth } from "../../auth/hooks/useAuth";
import { useEffect } from "react";
import { marketingConstants } from "./constants";

export const DownloadPage = () => {
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // Extract regionId from query parameters
  const regionId = searchParams.get("regionId");

  const handleSkip = () => {
    navigate(`/map/${regionId}`);
  };
  useEffect(() => {
    if (regionId && isAuthenticated) {
      handleSkip();
    }
  }, [regionId, isAuthenticated]);

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 6, mb: 6, textAlign: "center" }}>
        {/* Header */}
        <Typography variant="h3" gutterBottom>
          Download Trail Companion
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Explore trails in your region and beyond with Trail Companion.
          Download the app now to get started!
        </Typography>

        {/* Download Buttons */}
        <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AppleIcon />}
            component={Link}
            to={marketingConstants.iosStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            size="large"
          >
            Download on the App Store
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AndroidIcon />}
            component={Link}
            to={marketingConstants.androidStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            size="large"
          >
            Get it on Google Play
          </Button>
        </Stack>

        {/* Skip Download Option */}
        {regionId ? (
          <>
            <Typography variant="body1" color="textSecondary">
              Want to skip downloading? Explore trails directly!
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={handleSkip}
              sx={{ mt: 2 }}
            >
              Go to Map
            </Button>
          </>
        ) : undefined}
      </Container>
      <PageFooter />
    </>
  );
};
