export const TermsContent = {
  terms: `
Last Updated: 12/29/2024

Welcome to Trail Companion!

By using this trail navigation application ("the App"), you agree to the following terms and conditions:

## 1. Acceptance of Terms
By accessing or using the Trail Companion app, you acknowledge that you have read, understood, and agree to be bound by these terms.

## 2. App Purpose
Trail Companion is designed for off-road navigation and trail exploration. It is not intended for use on public roads or while driving. Please use the App responsibly and in accordance with local laws.

## 3. No Driving Usage
DO NOT use Trail Companion while operating a vehicle. Drivers must prioritize safety and comply with traffic regulations. Passengers may use the App while the vehicle is in motion, provided it does not distract the driver or compromise safety.

## 4. Trail Conditions
Trail conditions can change rapidly. Trail Companion provides information based on available data, but it may not be real-time. Users are responsible for assessing current trail conditions and adjusting their plans accordingly.

## 5. Assumption of Risk
Off-roading activities inherently involve risks. Users assume all risks associated with using Trail Companion, including but not limited to terrain challenges, weather conditions, and unforeseen obstacles.

## 6. Awareness of Surroundings and Obeying Signs
Users must be aware of their surroundings, obey all signs, and adhere to designated routes. Trail Companion is not a substitute for common sense and responsible trail use.

## 7. No Liability for Damages
Trail Companion LLC and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the App, including but not limited to personal injury, property damage, or loss of data.

## 8. User Responsibilities
Users are responsible for their actions while using Trail Companion. This includes complying with local laws, respecting private property rights, and ensuring the safety of themselves and others.

## 9. User Data
Trail Companion may collect and store user data for functionality and improvement purposes. By using the App, you consent to the collection and use of your data in accordance with our Privacy Policy.

## 10. App Updates
Trail Companion may receive updates to enhance features, security, or performance. Users are encouraged to keep the App updated for the best experience.

## 11. Termination
Trail Companion LLC reserves the right to terminate or suspend access to the App, with or without notice, for any reason.

## 12. Governing Law
This agreement is governed by and construed in accordance with the laws of [Your Jurisdiction].

By continuing to use Trail Companion, you agree to abide by these terms and conditions.

If you do not agree with any of these terms, please refrain from using the App.

Thank you for using Trail Companion and happy off-roading!

**Trail Companion LLC**  
**Contact Information:** [support@trailcompanion.app](mailto:support@trailcompanion.app)
  `,
  privacyPolicy: `
Last Updated: 12/29/2024

Thank you for using Trail Companion! This Privacy Policy outlines how Trail Companion LLC ("we," "our," or "us") collects, uses, and protects your personal information when you use the Trail Companion application ("the App").

## 1. Information We Collect

### a. Personal Information
We may collect personal information such as:
- Name
- Email address
- Location data (used to enhance navigation and provide trail-specific features)

### b. Usage Information
We collect information about how you interact with the App, such as:
- Trails explored
- Waypoints marked
- Preferences and settings

This data helps us improve the App and tailor the experience to your needs.

## 2. How We Use Your Information

### a. Personalization
We use your personal information to:
- Provide tailored recommendations
- Deliver relevant information based on your location and preferences

### b. Service Improvement
We analyze usage data to:
- Enhance the App’s functionality
- Troubleshoot issues
- Develop new features

## 3. Information Sharing
We do not sell, trade, or share your personal information with third parties, except in the following cases:
- To comply with legal obligations
- With your explicit consent
- Sharing anonymized, aggregated data for analytical or marketing purposes

## 4. Data Security
We take reasonable measures to protect your personal information against unauthorized access, disclosure, alteration, and destruction. However, no method of electronic transmission or storage is entirely secure, and we cannot guarantee absolute security.

## 5. Your Choices
You can manage your data preferences, including:
- Limiting location tracking through your device settings
- Adjusting permissions for notifications and other features

Note: Disabling certain permissions may limit the functionality of the App.

## 6. Third-Party Links
Trail Companion may include links to third-party websites or services. We are not responsible for the privacy practices of these external sites. Please review their policies before sharing personal information.

## 7. Children’s Privacy
Trail Companion is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us at [support@trailcompanion.app](mailto:support@trailcompanion.app), and we will take appropriate action.

## 8. Changes to this Privacy Policy
We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest update will be indicated at the top. Continued use of the App after changes signifies acceptance of the revised policy.

## 9. Contact Information
If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:

**Trail Companion LLC**  
Email: [support@trailcompanion.app](mailto:support@trailcompanion.app)

By using Trail Companion, you agree to the terms outlined in this Privacy Policy. Thank you for trusting us with your information!
`,
};
