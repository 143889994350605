import { Box, Toolbar } from "@mui/material";
import React from "react";

type Props = React.PropsWithChildren & {};

const PageTemplate = ({ children }: Props) => {
  return (
    <Box
      sx={{
        mt: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 500,
          width: "100%",
          gap: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageTemplate;
