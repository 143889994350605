import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import {
  Outlet,
  Routes,
  Route,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import { EditMapPage, ViewMapPage } from "./pages/map/MapPage";
import BarcodePage from "./pages/barcode/BarcodePage";
import LoginPage from "./pages/login/LoginPage";
import RequireAuth from "./util/auth/RequireAuth";
import DashboardPage from "./pages/dashboard/DashboardPage";
import AuthProvider from "./util/auth/AuthProvider";
import { Permissions } from "./lib";
import PermGuard from "./util/auth/PermGuard";
import SandboxPage from "./pages/sandbox/SandboxPage";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { TermsPage } from "./pages/disclosures/TermsPage";
import { useAuth } from "./auth/hooks/useAuth";
import { Paper } from "@mui/material";
import { BusinessOwnersPage } from "./pages/marketing/BusinessOwnersPage";
import { FaqPage } from "./pages/marketing/FaqPage";
import { ContactUsPage } from "./pages/marketing/ContactUsPage";
import { DownloadPage } from "./pages/marketing/DownloadPage";
import { ChangePasswordPage } from "./pages/account/ChangePasswordPage";
import { ForgotPasswordPage } from "./pages/account/ForgotPasswordPage";
import { FormSandboxPage } from "./sandbox/FormSandboxPage";
import { ResetPasswordPage } from "./pages/account/ResetPasswordPage";

const windowAny = window as any;

// router with auth example https://github.com/remix-run/react-router/tree/dev/examples/auth/src

const Layout = () => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const navigate = useNavigate();
  const { logout, isHidingToolbar } = useAuth();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!isHidingToolbar && (
        <Box>
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar>
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                <Link
                  to={token ? "/dashboard" : "/"}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Trail Companion
                </Link>
              </Typography>
              {token ? (
                <Button color="inherit" onClick={logout}>
                  Logout
                </Button>
              ) : (
                <Button color="inherit" onClick={() => navigate("/login")}>
                  Log in
                </Button>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Box
        sx={{
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!isHidingToolbar && <Toolbar />}
        <Paper
          sx={{
            width: "100%",
            overflowY: "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </Paper>
      </Box>
    </Box>
  );
};
type Route = {
  path: string;
  requireLogin: boolean;
  Element: () => JSX.Element;
  perms?: Permissions.Perm;
};
const routes: Route[] = [
  { path: "/", requireLogin: false, Element: HomePage },
  { path: "/login", requireLogin: false, Element: LoginPage },
  { path: "/dashboard", requireLogin: true, Element: DashboardPage },
  {
    path: "/map/:regionId",
    requireLogin: false,
    Element: ViewMapPage,
  },
  {
    path: "/map/:regionId/edit",
    requireLogin: true,
    Element: EditMapPage,
  },
  { path: "/barcode", requireLogin: true, Element: BarcodePage },
  { path: "/sandbox", requireLogin: false, Element: SandboxPage },
  { path: "/sandbox/form", requireLogin: false, Element: FormSandboxPage },
  { path: "/terms", requireLogin: false, Element: TermsPage },
  { path: "/faqs", requireLogin: false, Element: FaqPage },
  { path: "/contact", requireLogin: false, Element: ContactUsPage },
  { path: "/download", requireLogin: false, Element: DownloadPage },
  {
    path: "/business-owners",
    requireLogin: false,
    Element: BusinessOwnersPage,
  },
  {
    path: "/account/change-password",
    requireLogin: true,
    Element: ChangePasswordPage,
  },
  {
    path: "/account/forgot-password",
    requireLogin: false,
    Element: ForgotPasswordPage,
  },
  {
    path: "/account/reset-password",
    requireLogin: false,
    Element: ResetPasswordPage,
  },
];
function App() {
  return (
    <AuthProvider>
      <Box sx={{ width: "100%", minHeight: "100%" }}>
        <Routes>
          <Route element={<Layout />}>
            {routes.map(({ path, requireLogin, Element }) => (
              <Route
                key={path}
                path={path}
                element={
                  requireLogin ? (
                    <RequireAuth>
                      <Element />
                    </RequireAuth>
                  ) : (
                    <Element />
                  )
                }
              />
            ))}
          </Route>
        </Routes>
      </Box>
    </AuthProvider>
  );
}

export default App;
