import { Typography } from "@mui/material";
import { useAuth } from "../../auth/hooks/useAuth";
import PageTemplate from "../../components/page/PageTemplate";
import { FormPageLayout } from "../../components/tc-form/FormPageLayout";
import { FormSchema } from "../../components/tc-form/types";
import { useForgotPasswordMutation } from "../../util/client/TcApiV2";
import { TcLink } from "../../components/basic/TcLink";

const schema: FormSchema = [
  [
    {
      type: "email",
      propName: "email",
      label: "Email Address",
      required: true,
    },
  ],
];

type ChangePasswordForm = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const { isAuthenticated } = useAuth();
  const [trigger, { isSuccess, isError, isLoading }] =
    useForgotPasswordMutation();

  if (isAuthenticated) {
    return (
      <PageTemplate>
        <Typography>
          Unable to reset password while logged in.{" "}
          <TcLink to="/dashboard">Back to dashboard</TcLink>
        </Typography>
      </PageTemplate>
    );
  }
  return (
    <FormPageLayout<ChangePasswordForm>
      schema={schema}
      title={"Forgot Password"}
      isLoading={isLoading || isSuccess}
      onSubmit={(data) => {
        trigger({ payload: { email: data.email } });
      }}
    >
      <Typography>
        Already have a code? Enter it{" "}
        <TcLink to="/account/reset-password">here</TcLink>
      </Typography>
      {isError ? <Typography>Unable to send an email.</Typography> : undefined}
      {isSuccess ? (
        <Typography>
          Check your email for a reset code, then head over to the{" "}
          <TcLink to="/account/reset-password">reset page</TcLink>
        </Typography>
      ) : undefined}
    </FormPageLayout>
  );
};
