import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { tcEndpoints } from "@trailcompanion/tc-common/src/endpoints";

export const tcApiV2 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    timeout: 180000,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state?.auth?.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
    responseHandler: async (response) => {
      console.log("response: ", response.status);
      // localStorage.removeItem()
      return response.json();
    },
  }),
  endpoints: (builder) => ({}),
});

export const authApi = tcApiV2.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.login.requestFn>,
      Parameters<typeof tcEndpoints.auth.login.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.login.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.login.method,
        body: req.payload,
      }),
    }),
    signup: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.signup.requestFn>,
      Parameters<typeof tcEndpoints.auth.signup.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.signup.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.signup.method,
        body: req.payload,
      }),
    }),
    verifyToken: builder.query<
      ReturnType<typeof tcEndpoints.auth.verifyToken.requestFn>,
      Parameters<typeof tcEndpoints.auth.verifyToken.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.verifyToken.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.verifyToken.method,
        body: req.payload,
      }),
    }),
    changePassword: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.changePassword.requestFn>,
      Parameters<typeof tcEndpoints.auth.changePassword.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.changePassword.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.changePassword.method,
        body: req.payload,
      }),
    }),
    resetPassword: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.resetPassword.requestFn>,
      Parameters<typeof tcEndpoints.auth.resetPassword.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.resetPassword.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.resetPassword.method,
        body: req.payload,
      }),
    }),
    forgotPassword: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.forgotPassword.requestFn>,
      Parameters<typeof tcEndpoints.auth.forgotPassword.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.forgotPassword.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.forgotPassword.method,
        body: req.payload,
      }),
    }),
  }),
});

export const regionApi = tcApiV2.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    listRegions: builder.query<
      ReturnType<typeof tcEndpoints.region.listRegions.requestFn>,
      Parameters<typeof tcEndpoints.region.listRegions.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.listRegions.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.listRegions.method,
        body: req.payload,
      }),
    }),
    getRegionMap: builder.query<
      ReturnType<typeof tcEndpoints.region.getRegionMap.requestFn>,
      Parameters<typeof tcEndpoints.region.getRegionMap.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.getRegionMap.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.getRegionMap.method,
        body: req.payload,
      }),
    }),
    createRegion: builder.mutation<
      ReturnType<typeof tcEndpoints.region.createRegion.requestFn>,
      Parameters<typeof tcEndpoints.region.createRegion.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.createRegion.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.createRegion.method,
        body: req.payload,
      }),
    }),
    updateRegionMap: builder.mutation<
      ReturnType<typeof tcEndpoints.region.updateRegionMap.requestFn>,
      Parameters<typeof tcEndpoints.region.updateRegionMap.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.updateRegionMap.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.updateRegionMap.method,
        body: req.payload,
      }),
    }),
    copyRegion: builder.mutation<
      ReturnType<typeof tcEndpoints.region.copyRegion.requestFn>,
      Parameters<typeof tcEndpoints.region.copyRegion.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.copyRegion.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.copyRegion.method,
        body: req.payload,
      }),
    }),
    updateRegionVisibility: builder.mutation<
      ReturnType<typeof tcEndpoints.region.updateRegionVisibility.requestFn>,
      Parameters<typeof tcEndpoints.region.updateRegionVisibility.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.updateRegionVisibility.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.updateRegionVisibility.method,
        body: req.payload,
      }),
    }),
    deleteRegion: builder.mutation<
      ReturnType<typeof tcEndpoints.region.deleteRegion.requestFn>,
      Parameters<typeof tcEndpoints.region.deleteRegion.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.deleteRegion.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.deleteRegion.method,
        body: req.payload,
      }),
    }),
  }),
});

// template for adding another service
// export const regionApi = tcApiV2.injectEndpoints({
//   overrideExisting: false,
//   endpoints: (builder) => ({}),
// });

export const {
  useLoginMutation,
  useSignupMutation,
  useVerifyTokenQuery,
  useLazyVerifyTokenQuery,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = authApi;
export const {
  useListRegionsQuery,
  useLazyGetRegionMapQuery,
  useCreateRegionMutation,
  useUpdateRegionMapMutation,
  useCopyRegionMutation,
  useUpdateRegionVisibilityMutation,
  useDeleteRegionMutation,
} = regionApi;
